import React, { Fragment, useState } from "react";
import { FieldArray, Field } from "formik";
import classNames from "classnames";

import styles from "./consultationForm.module.scss";

import Input from "../../Templates/Forms/Input";
// import chiefComplaints from "../../../assets/chiefComplaints.json";

import Datalist from "../../Templates/Forms/Datalist";
/* eslint-disable */

const ChiefComplaintFields = ({
  index,
  arrayHelpers,
  values,
  setFieldValue,
  chiefComplaintList,
}) => {
  const chiefComplaints = chiefComplaintList.map(chiefComplaint => ({
    value: chiefComplaint.description,
    label: chiefComplaint.description,
  }));
  const [isDisabled, setDisabled] = useState(
    !values.chiefComplaint[index].description
  );
  const [initialValue, setInitialValue] = useState(
    values.chiefComplaint[index].description
      ? {
          value: values.chiefComplaint[index].description,
          label: values.chiefComplaint[index].description,
        }
      : null
  );
  const [selected, setSelected] = useState(null);

  return (
    <div className="columns mb-0" name="chiefComplaintRow">
      <div className="column pb-0 is-4">
        <Field name={`chiefComplaint[${index}]`}>
          {({ field, form }) => (
            <Datalist
              name={`chiefComplaint[${index}].description`}
              placeholder="Select from list below..."
              options={chiefComplaints}
              defaultValue={initialValue}
              field={field}
              hasLabel={true}
              label="Chief Complaint"
              isRequired={true}
              onChange={selected => {
                setSelected(selected);
                if (selected === "") setDisabled(true);
                else setDisabled(false);
                form.setFieldValue(
                  `chiefComplaint[${index}].description`,
                  selected.value
                );
              }}
            />
          )}
        </Field>
      </div>
      {index < 1 && (
        <div className="column pb-0 is-expanded">
          <Input
            label="History of Present Illness"
            name={`historyOfPresentIllness`}
            type="text"
            placeholder="History"
            isDisabled={isDisabled}
          />
        </div>
      )}
      {index !== 0 && (
        <div className="column is-1">
          <button
            className={classNames("delete is-medium mt-5", styles.removeButton)}
            onClick={() => arrayHelpers.remove(index)}
            type="button"
            id={`removeButton${index}`}
          >
            {/* <FontAwesomeIcon icon={faTimes} className="has-text-danger" /> */}
          </button>
        </div>
      )}
    </div>
  );
};

const ChiefComplaintAssessment = props => {
  const { errors, setFieldValue } = props;
  const ChiefComplaintErrors = errors => {
    return errors && typeof errors.description === "string" ? (
      <div className="has-text-danger is-size-7 mb-2">{errors.description}</div>
    ) : null;
  };

  return (
    <>
      <FieldArray
        name="chiefComplaint"
        render={arrayHelpers => (
          <>
            {props.values &&
              props.values.chiefComplaint &&
              props.values.chiefComplaint.map((chiefComplaint, index) => (
                <Fragment key={index}>
                  <ChiefComplaintFields
                    key={index}
                    index={index}
                    values={props.values}
                    chiefComplaintList={props.chiefComplaintList}
                    arrayHelpers={arrayHelpers}
                    setFieldValue={setFieldValue}
                  />
                  {errors.chiefComplaint
                    ? ChiefComplaintErrors(errors.chiefComplaint[index])
                    : null}
                </Fragment>
              ))}

            {/* {props.values.chiefComplaint &&
            props.values.chiefComplaint.length < 2 ? (
              <span
                className="link is-size-7"
                onClick={() => {
                  arrayHelpers.push({});
                  return props.values.chiefComplaint.length + 1;
                }}
                id="addChiefComplaint"
              >
                + Add another chief complaint
              </span>
            ) : (
              <span className="is-size-7 has-text-danger">
                A consultation can only have a maximum of 2 chief complaints.
              </span>
            )} */}
          </>
        )}
      />
      <br />
      <br />
      <div className={"columns"}>
        <div className={"column is-3"}>
          {/* ---------------------------------------------------- */}
          <Input
            label="Weight (kg)"
            type="number"
            min={0}
            name="weightKg"
            placeholder="40"
          />
          {/* ---------------------------------------------------- */}
          <Input
            label="Height (cm)"
            type="number"
            min={0}
            name="heightCm"
            placeholder="160"
          />
          {/* ---------------------------------------------------- */}
        </div>

        <div className={classNames("column is-3 is-offset-1")}>
          {/* ---------------------------------------------------- */}
          <div className="field mb-0">
            <label
              className={classNames(
                "label has-text-weight-normal has-text-grey-dark mt-1"
              )}
            >
              Blood pressure (mmHg)
            </label>
            <div className="field-body">
              <div className="field">
                <Input
                  className="input"
                  type="number"
                  min={4}
                  name="systolicMmhg"
                  placeholder="120"
                />
              </div>
              <p className="control mr-1 mt-1">/</p>
              <div className="field">
                <Input
                  className="input"
                  type="number"
                  min={4}
                  name="diastolicMmhg"
                  placeholder="80"
                />
              </div>
            </div>
          </div>
          {/* <Input type="number" min={1}" name="diastolicMmhg" placeholder="80" /> */}
          {/* --------------------------------------------------- */}
          <Input
            label="Temperature (°C)"
            type="number"
            step="any"
            min={3}
            name="bodyTemperatureC"
            placeholder="37"
          />
          {/* --------------------------------------------------- */}
        </div>
        <div className={classNames("column is-4 is-offset-1")}>
          {/* --------------------------------------------------- */}
          <Input
            label="Heart rate (beats per min)"
            type="number"
            min={1}
            name="heartRateBpm"
            placeholder="25"
          />
          {/* ---------------------------------------------------- */}
          <Input
            label="Respiratory rate (breaths per min)"
            type="number"
            min={5}
            name="respiratoryRateBpm"
            placeholder="15"
          />
          {/* ---------------------------------------------------- */}
        </div>
      </div>
    </>
  );
};

export default ChiefComplaintAssessment;
