import api from "./api";
import moment from "moment";
import { decamelizeKeys } from "humps";
import { act } from "react-test-renderer";

export function addConsultation(values, token, callback, errorCallback) {
  api
    .post("consultations/", values, {
      headers: {
        Authorization: `${token}`,
      },
    })
    .then(response => callback())
    .catch(error => errorCallback());
}

export function editConsultation(values, token, callback, errorCallback) {
  values = {
    ...values,
    medstaffEmail: values.medstaffEmail === "N/A" ? "" : values.medstaffEmail,
  };
  api
    .put(`consultations/${values.id}/`, values, {
      headers: {
        Authorization: `${token}`,
      },
    })
    .then(response => callback())
    .catch(error => errorCallback());
}

export function reconstructConsultationValues(
  values,
  clinicStaff,
  clinicName,
  siteName,
  clientId,
  customFields
) {
  return {
    updatedBy: sessionStorage.getItem("staffId"),
    account: values.employee.employee.department,
    employee: {
      firstName: values.employee.firstName || "",
      lastName: values.employee.lastName || "",
      employeeNumber: values.employee.employee.employeeNumber || "",
      individualId: String(values.employee.id) || "",
      sex: values.employee.sex,
      site: values.employeeSite || null,
    },
    recommendation: values.recommendation,
    chiefComplaint: values.chiefComplaint,
    historyOfPresentIllness: values.historyOfPresentIllness,
    nurseAssessment: values.nurseAssessment,
    assessmentNotes: values.assessmentNotes,
    doctorsDiagnosis: values.doctorsDiagnosis,
    diagnosisNotes: values.diagnosisNotes,
    clinicStaff: clinicStaff,
    medstaffEmail: values.medstaffEmail === "N/A" ? "" : values.medstaffEmail,
    clinic: {
      name: clinicName,
      site: siteName,
      client: { id: parseInt(clientId) },
    },
    created: moment()
      .utc()
      .format(),
    logs: values.logs.map(item => {
      return {
        productCode: item.product,
        quantity: item.quantity,
        reason: "dispensed",
        created: moment()
          .utc()
          .format(),
      };
    }),
    bodyTemperatureC: values.bodyTemperatureC || null,
    diastolicMmhg: values.diastolicMmhg || null,
    heartRateBpm: values.heartRateBpm || null,
    heightCm: values.heightCm || null,
    respiratoryRateBpm: values.respiratoryRateBpm || null,
    systolicMmhg: values.systolicMmhg || null,
    weightKg: values.weightKg || null,
    remarkableFindings: values.remarkableFindings || null,
    clinicPasses: values.clinicPasses || null,
    notes: values.notes || null,
    customFields: customFields,
    consultType: values.consultType,
    status: values.consultType === "Physical" ? "Completed" : values.status,
    scheduled: !!values.scheduled
      ? moment(values.scheduled)
          .utc()
          .format()
      : null,
  };
}

export function initializeConsultationValues(
  type,
  activeConsultation,
  userDetails
) {
  switch (type) {
    case "edit":
      let customFields = {};
      if (activeConsultation.customFields)
        Object.keys(activeConsultation.customFields).map(
          key => (customFields[key] = activeConsultation.customFields[key])
        );

      return {
        ...activeConsultation,
        medstaffEmail: activeConsultation.medstaffEmail || "N/A",
        ...decamelizeKeys(customFields),
        scheduled: activeConsultation.scheduled
          ? moment(activeConsultation.scheduled).format("YYYY-MM-DDTHH:mm")
          : null,
      };
    case "add":
      return {
        logs: [],
        chiefComplaint: [{}],
        employee: {
          firstName: "",
          lastName: "",
          employee: { employeeNumber: "", site: {} },
          site: null,
        },
        employeeName: "",
        employeeSite: "",
        doctor: { firstName: "" },
        nurseAssessment: "",
        doctorsDiagnosis: null,
        emailBody: "",
        supervisor: { firstName: "", lastName: "", email: "" },
        timeIn: moment().format("HH:mm"),
        timeOut: moment().format("HH:mm"),
        clinicPasses: [],
        clinicStaff: [],
        passesRecipients: [],
        effectivityDate: moment().format("YYYY-MM-DD"),
        duration: "",
        otherRecipients: [],
        historyOfPresentIllness: "",
        nurseName:
          userDetails &&
          `${userDetails.firstName} ${userDetails.lastName} (RN)`,
        status: "Completed",
        consultType: "Physical",
        scheduled: null,
        medstaffEmail: "",
      };
    default:
      break;
  }
}

export function successfulNotification(toast, values) {
  toast(
    `Successfully saved consultation for ${values.employee.firstName} ${
      values.employee.lastName
    }.`,
    "is-success",
    5000
  );
}

export function errorNotification(toast) {
  toast("An error occurred. Please double check your data.", "is-danger", 5000);
}

export function getIcd10(values, data, type) {
  if (
    values[type] &&
    !data.assessment.nodes.some(assessment => assessment.id === values[type])
  ) {
    return data.assessment.nodes.filter(
      item => item.description === values[type]
    )[0].id;
  } else return values[type];
}

export function validateConsultationValues(toast, values, type) {
  if (values.diagnosis && (!values.doctor.id && type === "add")) {
    toast(
      "Please select a doctor for the doctor's diagnosis.",
      "is-warning",
      5000
    );
    return false;
  }

  if (!values.employee.firstName) {
    toast("Please select an employee.", "is-warning", 5000);
    return false;
  }

  for (let i = 0; i < values.chiefComplaint.length; i++)
    if (!Object.keys(values.chiefComplaint[i]).length) {
      toast("Please select a chief complaint.", "is-warning", 5000);
      return false;
    }

  return true;
}

export function generateCustomFieldsValue(values, name, dataType) {
  if (dataType === "boolean") return values[name] === "true";
  if (dataType === "pick_list_one")
    if (values[name] === "Other") return values[`${name}Notes`];
    else return values[name];
  else return values[name];
}
