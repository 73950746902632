import React, { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCog,
  faIdCard,
  faPhoneVolume,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import SearchBar from "../../Templates/Forms/SearchBar";
import Input from "../../Templates/Forms/Input";
import Panel from "../../Templates/Forms/Panel";
import Datalist from "../../Templates/Forms/Datalist";

import api from "../../../services/api";
import {
  EmployeeValidationSchema,
  SupervisorValidationSchema,
} from "../../Templates/Forms/ValidationSchema";

const DEFAULT_EMPLOYEE = {
  email: "",
  firstName: "",
  lastName: "",
  middleName: "",
  sex: "",
  contactNumber: "",
  idList: [],
  employee: {
    position: "",
    department: "",
    dateHired: "",
    employeeNumber: "",
  },
};

export const AddEmployee = ({
  setFieldValue,
  siteDetails,
  closeModal,
  isEditing,
  employee,
  supervisor,
  isSupervisor,
  employeeType,
}) => {
  const [isActive, setActiveButton] = useState(isEditing ? employee.sex : null);
  const [isLoading, setLoading] = useState(false);

  const reconstructValues = values => {
    return {
      id: values.id || null,
      email: values.email || "",
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName || "",
      contactNumber: values.contactNumber || "",
      sex: values.sex || "",
      idList: [],
      // clinic: "",
      employee: {
        site: {
          id: siteDetails.id,
          name: siteDetails.name,
          siteLetters: siteDetails.siteLetters,
          siteNumber: siteDetails.siteNumber,
          corporateClientId: siteDetails.corporateClient.id,
        },
        position: values.employee.position || "N/A",
        department: values.employee.department || "N/A",
        employeeNumber: values.employee.employeeNumber || "N/A",
        dateHired: values.employee.dateHired || null,
        birthday: values.employee.birthday || null,
      },
    };
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") event.preventDefault();
  };
  if (isSupervisor) {
    employeeType = "supervisor";
  } else {
    employeeType = "employee";
  }

  const validationSchema = isSupervisor
    ? SupervisorValidationSchema
    : EmployeeValidationSchema;

  return (
    <Formik
      id="addEmployeeForm"
      initialValues={isEditing ? employee : DEFAULT_EMPLOYEE}
      validationSchema={validationSchema}
      onSubmit={values => {
        setLoading(true);
        if (isEditing)
          api
            .put("consultations/employees/", reconstructValues(values), {
              headers: {
                Authorization: `${sessionStorage.getItem("loginToken")}`,
              },
            })
            .then(response => {
              if (isSupervisor) {
                setFieldValue("supervisor", response.data);
                setFieldValue(
                  "supervisorName",
                  `${response.data.firstName} ${response.data.lastName}`
                );
              } else {
                setFieldValue("employee", response.data);
                setFieldValue(
                  "employeeName",
                  `${response.data.firstName} ${response.data.lastName}`
                );
              }
              setLoading(false);
              closeModal();
            })
            .catch(error => {
              setLoading(false);
              console.error(error);
            });
        else
          api
            .post("consultations/employees/", reconstructValues(values), {
              headers: {
                Authorization: `${sessionStorage.getItem("loginToken")}`,
              },
            })
            .then(response => {
              if (isSupervisor) {
                setFieldValue("supervisor", response.data);
                setFieldValue(
                  "supervisorName",
                  `${response.data.firstName} ${response.data.lastName}`
                );
              } else {
                setFieldValue("employee", response.data);
                setFieldValue(
                  "employeeName",
                  `${response.data.firstName} ${response.data.lastName}`
                );
              }
              setLoading(false);
              closeModal();
            })
            .catch(error => {
              setLoading(false);
              console.error(error);
            });
      }}
    >
      {({ setFieldValue, errors }) => {
        return (
          <Form autoComplete="off" onKeyDown={handleKeyDown}>
            <Fragment>
              <Panel
                heading={
                  isSupervisor
                    ? "Supervisor Information"
                    : "Patient Information"
                }
              >
                <Input
                  label="First Name"
                  type="text"
                  name="firstName"
                  isRequired
                />
                {/* <Input label="Middle Name" type="text" name="middleName" /> */}
                <Input
                  label="Last Name"
                  type="text"
                  name="lastName"
                  isRequired
                />
                <Input
                  label="Birthday"
                  type="date"
                  name="employee.birthday"
                  // isRequired={!isSupervisor}
                />
                <div className="field has-addons pt-1">
                  <label className="label pr-1">
                    Sex
                    {!isSupervisor ? (
                      <span className="has-text-danger"> *</span>
                    ) : null}
                  </label>
                  <div className="buttons has-addons">
                    <button
                      name="sex"
                      className={classNames(
                        { "is-primary": isActive === "F" },
                        "button"
                      )}
                      type="button"
                      onClick={() => {
                        setActiveButton("F");
                        setFieldValue("sex", "F");
                      }}
                    >
                      Female
                    </button>
                    <button
                      name="sex"
                      className={classNames(
                        { "is-primary": isActive === "M" },
                        "button"
                      )}
                      type="button"
                      onClick={() => {
                        setActiveButton("M");
                        setFieldValue("sex", "M");
                      }}
                    >
                      Male
                    </button>
                  </div>
                </div>
                <div
                  className={classNames("has-text-danger is-size-7 mb-3", {
                    "is-invisible": !errors.sex,
                  })}
                >
                  {errors.sex || ""}
                </div>
                <Input
                  label="Email Address"
                  type="email"
                  name="email"
                  // isRequired
                />
                <Input
                  label="Mobile Number"
                  type="text"
                  name="contactNumber"
                  // isRequired
                />
              </Panel>

              <Panel heading="Employment Details">
                <Input
                  label="Employee Number"
                  type="text"
                  name="employee.employeeNumber"
                  placeholder="Type 'N/A' if no employee number"
                  // isRequired
                />
                <Input
                  // isRequired
                  name="employee.department"
                  label="Account"
                  placeholder="Type 'Other' if no account/department"
                  render={({ field, form, props }) => {
                    return (
                      <SearchBar
                        id="searchAccount"
                        endpoint="consultations/accounts"
                        resultDisplay={["account"]}
                        additionalParams={`clinic=${sessionStorage.getItem(
                          "clinicId"
                        )}`}
                        field={field}
                        form={form}
                        props={props}
                        isAutocomplete={true}
                        callbackFunction={result => {
                          setFieldValue("employee.department", result);

                          return result;
                        }}
                      />
                    );
                  }}
                />
                <Input
                  label="Position"
                  type="text"
                  name="employee.position"
                  placeholder="Type 'N/A' if no position"
                  // isRequired
                />
                <Input
                  label="Date Hired"
                  type="date"
                  name="employee.dateHired"
                />

                <div className="buttons mt-2 is-right">
                  <button
                    className={classNames("button is-primary", {
                      "is-loading": isLoading,
                    })}
                    type="submit"
                    name="saveEmployeeInfo"
                  >
                    {isSupervisor
                      ? "Save supervisor details"
                      : "Save employee details"}
                  </button>
                </div>
              </Panel>
            </Fragment>
          </Form>
        );
      }}
    </Formik>
  );
};

const SiteDropdown = ({ clinicList, onChange }) => (
  <p className="control mr-0">
    <span className="select">
      <select className="select" name="select-site" onChange={onChange}>
        {clinicList.map(item => (
          <option value={item.clinicId}>{item.clinicName}</option>
        ))}
      </select>
    </span>
  </p>
);

const Employee = props => {
  let { appContext, parentHasSidebar, isSupervisor, employeeType } = props;
  let siteId = appContext.siteDetails.id;
  let siteDetails = appContext.siteDetails;
  let { setFieldValue, values, errors } = props;
  const [clinicList, setClinicList] = useState([]);
  const [selectedSite, setSelectedSite] = useState(siteId);

  const [response, setResponse] = useState([]);

  const [otherClinics, setOtherClinics] = useState({});

  useEffect(() => {
    api
      .get(`clinics/?client__code=${sessionStorage.getItem("clientCode")}`)
      .then(response => {
        let dropdown = [];
        response.data.results.map(clinic => {
          dropdown.push({ value: clinic.name, label: clinic.name });
        });
        setOtherClinics(dropdown);
      })
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    let siteId = props.appContext.siteDetails.id;
    setSelectedSite(siteId);
    api
      .get(
        `employees/clinic_list/?clinic_staff_id=${sessionStorage.getItem(
          "staffId"
        )}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("loginToken")}`,
          },
        }
      )
      .then(response => {
        setResponse(response);
        let tempClinicList = [];
        tempClinicList.push({
          clinicId: siteId,
          clinicName: sessionStorage.getItem("clinicName"),
        });
        tempClinicList = [
          ...tempClinicList,
          ...response.data.message.filter(
            item => item.clinicName !== tempClinicList[0].clinicName
          ),
        ];
        setClinicList(tempClinicList);
      });
  }, [appContext]);

  const handleSelectChange = event => {
    setSelectedSite(event.target.value);
  };

  if (isSupervisor === true) {
    employeeType = "supervisor";
  } else {
    employeeType = "employee";
  }

  return (
    <Fragment>
      {selectedSite &&
        (isSupervisor
          ? !values.supervisor.firstName
          : !values.employee.firstName) && (
          <>
            <Input
              isRequired
              name={isSupervisor ? "supervisorName" : "employeeName"}
              label={isSupervisor ? "Supervisor Details" : ""}
              render={({ field, form, props }) => {
                return (
                  <SearchBar
                    hasLeftAddon={clinicList.length > 1}
                    leftAddon={
                      <SiteDropdown
                        clinicList={clinicList}
                        onChange={handleSelectChange}
                      />
                    }
                    id={isSupervisor ? "supervisorSearch" : "employeeSearch"}
                    field={field}
                    form={form}
                    props={props}
                    endpoint="consultations/employees"
                    placeholder={`Search for ${employeeType}...`}
                    resultDisplay={["firstName", "lastName"]}
                    additionalParams={`site_id=${selectedSite}`}
                    callbackFunction={result => {
                      if (isSupervisor) {
                        values.supervisor = result;
                        setFieldValue("supervisor", result);
                      } else {
                        values.employee = result;
                        setFieldValue("employee", result);
                      }
                    }}
                    isExpanded
                  />
                );
              }}
            />
            <p className="is-size-7">
              Can't find {employeeType}?{" "}
              <span
                className="link"
                name="addEmployeeLink"
                id="addEmployeeLink"
                onClick={() => {
                  props.appContext.useModal(
                    <AddEmployee
                      errors={errors}
                      siteDetails={siteDetails}
                      closeModal={props.appContext.closeModal}
                      setFieldValue={setFieldValue}
                      isSupervisor={isSupervisor}
                    />,
                    "primary",
                    `ADD NEW ${employeeType}`
                  );
                }}
              >
                <strong>Add new {employeeType}</strong>
              </span>
            </p>
          </>
        )}
      {(isSupervisor
        ? values.supervisor.firstName
        : values.employee.firstName) && (
        <>
          <article
            className={classNames("media", { "mb-3": !parentHasSidebar })}
          >
            <div className="media-left">
              <div className="content">
                <p className={classNames("is-size-5", isSupervisor && "mt-2")}>
                  <strong>
                    {isSupervisor ? values.supervisorName : values.employeeName}
                  </strong>
                  <br />
                  {!parentHasSidebar && (
                    <span className="is-size-6">
                      <span className="mr-2">
                        {isSupervisor ? (
                          <>
                            <FontAwesomeIcon icon={faUserCog} />{" "}
                            {values.supervisor.employee.department}
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faUserCog} />{" "}
                            {values.employee.employee.department}
                          </>
                        )}
                      </span>
                      <span className="mr-2">
                        {isSupervisor ? (
                          <>
                            <FontAwesomeIcon icon={faIdCard} />{" "}
                            {values.supervisor.employee.employeeNumber}
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faIdCard} />{" "}
                            {values.employee.employee.employeeNumber}
                          </>
                        )}
                      </span>
                      <span>
                        {isSupervisor ? (
                          <>
                            <FontAwesomeIcon icon={faPhoneVolume} />{" "}
                            {values.supervisor.contactNumber}
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faPhoneVolume} />{" "}
                            {values.employee.contactNumber}
                          </>
                        )}
                      </span>
                    </span>
                  )}
                </p>
              </div>
            </div>
            {!parentHasSidebar && (
              <div
                className={classNames(
                  "media-right",
                  isSupervisor ? "mt-3" : "mt-1"
                )}
              >
                <button
                  className="button is-primary"
                  title={`Edit ${employeeType} details`}
                  type="button"
                  name="editEmployeeButton"
                  id="editEmployeeButton"
                  onClick={() => {
                    props.appContext.useModal(
                      <AddEmployee
                        siteDetails={siteDetails}
                        closeModal={props.appContext.closeModal}
                        setFieldValue={setFieldValue}
                        employee={
                          isSupervisor ? values.supervisor : values.employee
                        }
                        isSupervisor={isSupervisor}
                        isEditing={true}
                      />,
                      "primary",
                      `EDIT ${employeeType}`
                    );
                  }}
                >
                  <span className="icon is-left">
                    <FontAwesomeIcon icon={faEdit} />
                  </span>
                </button>
              </div>
            )}
          </article>
          <p className="is-size-7">
            Not this {employeeType}?{" "}
            <span
              className="link"
              name="addEmployeeLink"
              id="changeEmployeeLink"
              onClick={() => {
                if (isSupervisor) {
                  setFieldValue("supervisor", {});
                  setFieldValue("supervisorName", "");
                } else {
                  setFieldValue("employee", {});
                  setFieldValue("employeeName", "");
                }
              }}
            >
              <strong>Change {employeeType}</strong>
            </span>
          </p>
        </>
      )}
      {values.consultType === "Teleconsult" ? (
        <Fragment>
          <br />
          <Datalist
            options={otherClinics}
            name="employeeSite"
            label="Employee Site"
            hasLabel
            placeholder="Select clinic..."
            onChange={selected => {
              setFieldValue("employeeSite", selected.value);
            }}
            isRequired
          />
          {!!errors.employeeSite ? (
            <p className="has-text-danger is-size-7">{errors.employeeSite}</p>
          ) : null}
        </Fragment>
      ) : null}
    </Fragment>
  );
};

AddEmployee.defaultProps = {
  isSupervisor: false,
};

Employee.defaultProps = {
  parentHasSidebar: true,
  isSupervisor: false,
};

export default Employee;
