import React, { useContext, useState, useEffect, Fragment } from "react";
import { navigate, useStaticQuery, graphql, Link } from "gatsby";
import { Formik, Form } from "formik";
import classNames from "classnames";
import {
  faRunning,
  faUserMd,
  faPills,
  faNotesMedical,
  faUserTie,
  faCheck,
  faEnvelope,
  faTimes,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import Layout from "../../layout/Layout";
import Panel from "../Templates/Forms/Panel";
import ChiefComplaintAssessment from "./ConsultationForm/ChiefComplaintAssessment";
import MedicalAssessment from "./ConsultationForm/MedicalAssessment";
import Medicine from "./ConsultationForm/Medicine";
import Recommendation from "./ConsultationForm/Recommendation";
import EmployeeDetails from "./ConsultationSidebar/EmployeeDetails";
import ConsultationEmailModal from "./ConsultationForm/ConsultationEmailModal";

import Employee from "./ConsultationForm/Employee";
import MedicineTable from "./ViewConsultation/MedicineTable";
import CustomFields from "./ConsultationForm/CustomFields";
import ConsultType from "./ConsultationForm/ConsultType";

import AppContext from "../../context/AppContext";
import {
  AddConsultationValidationSchema,
  EditConsultationValidationSchema,
} from "../Templates/Forms/ValidationSchema";
import { getProductTitle } from "./ViewConsultation/ViewConsultation";
import {
  addConsultation,
  editConsultation,
  reconstructConsultationValues,
  initializeConsultationValues,
  getIcd10,
  successfulNotification,
  errorNotification,
  validateConsultationValues,
  generateCustomFieldsValue,
} from "../../services/consultationUtils";
import api from "../../services/api";

const handleKeyDown = event => {
  if (event.key === "Enter") event.preventDefault();
};

const ConsultationForm = ({ type }) => {
  const data = useStaticQuery(graphql`
    query data {
      assessment: allAssessmentDiagnosis(
        sort: { fields: label, order: ASC }
        filter: { isActive: { eq: true } }
      ) {
        nodes {
          description
          label
          id
        }
      }
      chiefComplaint: allChiefComplaint(
        sort: { fields: description, order: ASC }
      ) {
        nodes {
          description
        }
      }
    }
  `);
  const chiefComplaintList = data.chiefComplaint.nodes;
  const assessmentDiagnosisList = data.assessment.nodes;
  const [isLoading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [pageTitle, setPageTitle] = useState("New Consultation");
  const [customFields, setCustomFields] = useState([]);
  const [emptyRequiredCustomFields, setEmptyRequiredCustomFields] = useState(
    []
  );

  const [clientCode, setClientCode] = useState("");
  const [acceptsTeleconsults, setAcceptsTeleconsults] = useState(false);
  const appContext = useContext(AppContext);
  const { activeConsultation } = appContext;

  const handleSubmit = toast => values => {
    let customFieldsDict = {};
    let tempEmptyRequiredFields = [];
    customFields.map(item => {
      if (values[item.name])
        customFieldsDict[item.name] = generateCustomFieldsValue(
          values,
          item.name,
          item.dataType
        );
      else if (item.isRequired) tempEmptyRequiredFields.push(item.name);
    });

    if (tempEmptyRequiredFields.length > 0) {
      setEmptyRequiredCustomFields(tempEmptyRequiredFields);
      return;
    }

    values.nurseAssessment = getIcd10(values, data, "nurseAssessment");
    values.doctorsDiagnosis = getIcd10(values, data, "doctorsDiagnosis");

    let isValid = validateConsultationValues(toast, values, type);
    if (!isValid) return;
    let clinicStaff = new Set([
      ...values.clinicStaff,
      parseInt(sessionStorage.getItem("staffId")),
    ]);
    if (values.doctor.id) {
      clinicStaff.add(parseInt(values.doctor.id));
      if (activeConsultation.doctor) {
        const oldDoctor = parseInt(activeConsultation.doctor.id);
        const newDoctor = parseInt(values.doctor.id);
        if (clinicStaff.has(oldDoctor) && oldDoctor !== newDoctor)
          clinicStaff.delete(oldDoctor);
      }
    }
    setLoading(true);
    switch (type) {
      case "add":
        addConsultation(
          reconstructConsultationValues(
            values,
            Array.from(clinicStaff),
            sessionStorage.getItem("clinicName"),
            sessionStorage.getItem("siteName"),
            sessionStorage.getItem("clientId"),
            customFieldsDict
          ),
          sessionStorage.getItem("loginToken"),
          () => {
            setLoading(false);
            successfulNotification(toast, values);
            navigate("/consultations");
          },
          () => {
            setLoading(false);
            errorNotification(toast);
          }
        );
        break;
      case "edit":
        editConsultation(
          {
            ...values,
            scheduled: values.scheduled
              ? moment(values.scheduled).format()
              : null,
            logs: values.inventoryLogs,
            clinicStaff: Array.from(clinicStaff),
            updatedBy: sessionStorage.getItem("staffId"),
            // medstaffEmail: values.medstaffEmail.value,
            medstaffEmail: values.medstaffEmail,
          },
          sessionStorage.getItem("loginToken"),
          () => {
            setLoading(false);
            successfulNotification(toast, values);
            navigate("/consultations");
          },
          () => {
            setLoading(false);
            errorNotification(toast);
          }
        );
        break;
      default:
        break;
    }
    return null;
  };

  useEffect(() => {
    let tempClientCode = sessionStorage.getItem("clientCode");
    let teleconsults = sessionStorage.getItem("teleconsults");
    setAcceptsTeleconsults(teleconsults);
    setClientCode(tempClientCode);
    if (type === "edit") {
      if (!activeConsultation.id) navigate("/consultations");
      else {
        const firstName = activeConsultation.employee.firstName;
        const lastName = activeConsultation.employee.lastName;
        setPageTitle(`Edit Consultation - ${lastName}, ${firstName}`);
      }
      if (activeConsultation.inventoryLogs) {
        let titles = activeConsultation.inventoryLogs.map(item =>
          getProductTitle(item.productCode)
        );
        Promise.all(titles).then(response => setProductList(response));
      }
    }
    api
      .get(`custom-fields/?client=${tempClientCode}&model=consultation`)
      .then(response => {
        setCustomFields(response.data);
      });
  }, []);

  // const getEmailButtonText = () => {
  //   switch (clientCode) {
  //     case "SI":
  //       return "Please send an email to the patient's supervisor via Outlook.";
  //     default:
  //       return "Send emails";
  //   }
  // };

  // const checkIfDisabled = () => {
  //   return clientCode === "SI";
  // };

  return (
    <Formik
      initialValues={initializeConsultationValues(
        type,
        activeConsultation,
        appContext.userDetails
      )}
      onSubmit={handleSubmit(appContext.useToast)}
      validationSchema={
        type === "add"
          ? AddConsultationValidationSchema
          : EditConsultationValidationSchema
      }
    >
      {formikHelpers => {
        const handlePillClick = event => {
          const target = event.currentTarget;
          formikHelpers.setFieldValue(target.name, target.id);
          if (target.name === "status" && type === "add") {
            // This code here manipulates the Scheduled field if Status changes
            // If status is Completed upon "add", scheduled should be null
            // If status is Scheduled upon "add", scheduled is auto-now as default value
            // This behavior should only ring true on "add" mode, because on "edit" it should get the consult's original inputted Scheduled value
            if (target.id === "Scheduled") {
              formikHelpers.setFieldValue(
                "scheduled",
                moment().format("YYYY-MM-DDTHH:mm")
              );
            } else if (target.id === "Completed") {
              formikHelpers.setFieldValue("scheduled", "");
            }
          }
        };
        return (
          <Form
            className="clinic-forms"
            autoComplete="off"
            onKeyDown={handleKeyDown}
          >
            <Layout
              pageTitle={pageTitle}
              hasSidebar={type === "add"}
              sidebarContent={
                type === "add" ? (
                  <EmployeeDetails
                    assessmentDiagnosisList={assessmentDiagnosisList}
                    siteId={appContext.siteDetails.id}
                    {...formikHelpers}
                  />
                ) : null
              }
            >
              <div className="columns mb-0 mt-1">
                <div className="column pb-0">
                  {acceptsTeleconsults === "true" ? (
                    <ConsultType
                      values={formikHelpers.values}
                      handleClick={handlePillClick}
                      errors={formikHelpers.errors}
                    />
                  ) : null}
                  {type === "add" && (
                    <Fragment>
                      <Panel
                        heading="Employee Details"
                        icon={faUserTie}
                        isRequired
                      >
                        <Employee appContext={appContext} {...formikHelpers} />
                      </Panel>
                    </Fragment>
                  )}
                  <Panel heading="Chief Complaint Assessment" icon={faRunning}>
                    <ChiefComplaintAssessment
                      {...formikHelpers}
                      chiefComplaintList={chiefComplaintList}
                    />
                  </Panel>
                  <Panel
                    heading="Medical Assessment"
                    icon={faUserMd}
                    isRequired
                  >
                    <MedicalAssessment
                      assessmentDiagnosisList={assessmentDiagnosisList}
                      chiefComplaintList={chiefComplaintList}
                      appContext={appContext}
                      formType={type}
                      {...formikHelpers}
                    />
                  </Panel>
                  {type === "add" ? (
                    <Panel heading="Medicines" icon={faPills}>
                      <Medicine appContext={appContext} {...formikHelpers} />
                    </Panel>
                  ) : (
                    Object.keys(activeConsultation.inventoryLogs).length >
                      0 && (
                      <Panel heading="Medicines" icon={faPills}>
                        <MedicineTable
                          values={activeConsultation}
                          productTitles={productList}
                        />
                      </Panel>
                    )
                  )}
                  <Panel
                    heading="Recommendation"
                    icon={faNotesMedical}
                    // isRequired
                  >
                    <Recommendation
                      {...formikHelpers}
                      hasCustomFields={customFields.length > 0}
                    />
                  </Panel>

                  {type === "add" &&
                    !!customFields &&
                    Object.keys(customFields).length > 0 && (
                      <Panel heading="Referrals and Remarks" icon={faClipboard}>
                        <CustomFields
                          customFields={customFields}
                          emptyFields={emptyRequiredCustomFields}
                          values={formikHelpers.values}
                        />
                      </Panel>
                    )}

                  {Object.keys(formikHelpers.errors).length > 0 ? (
                    <div className="message is-danger">
                      <div className="message-body">
                        You may have missed some required fields. Please run
                        through the form again before submitting.
                      </div>
                    </div>
                  ) : null}
                  <div className="columns">
                    <div className="column">
                      <button
                        type="button"
                        className="button"
                        // disabled={checkIfDisabled()}
                        onClick={() => {
                          appContext.useModal(
                            <ConsultationEmailModal
                              data={formikHelpers.values}
                            />,
                            "primary",
                            "GENERATE CONSULTATION EMAIL TEXT"
                          );
                        }}
                      >
                        <span className="icon">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </span>{" "}
                        <span>Generate emails</span>
                      </button>
                    </div>

                    <div className="column">
                      <div className="buttons mb-5 is-right">
                        <Link
                          to="/consultations"
                          className="button is-danger is-outlined"
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
                          <span>Cancel</span>
                        </Link>
                        <button
                          type="submit"
                          className={classNames("button is-primary", {
                            "is-loading": isLoading,
                          })}
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={faCheck} />
                          </span>{" "}
                          <span>Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ConsultationForm;
