import React, { useState, useEffect } from "react";
import {
  faUserMd,
  faNotesMedical,
  faRunning,
  faPills,
} from "@fortawesome/free-solid-svg-icons";

import Panel from "../../Templates/Forms/Panel";
import MedicalAssessmentTable from "../ViewConsultation/MedicalAssessmentTable";
import RecommendationTable from "../ViewConsultation/RecommendationTable";
import PhysicalAssessmentTable from "../ViewConsultation/PhysicalAssessmentTable";
import { getProductTitle } from "../ViewConsultation/ViewConsultation";
import MedicineTable from "../ViewConsultation/MedicineTable";

const PastConsultationModal = ({ data, assessmentDiagnosisList }) => {
  const [medicines, setMedicines] = useState([]);

  const getDescription = icd10 => {
    for (let i = 0; i < assessmentDiagnosisList.length; i++)
      if (assessmentDiagnosisList[i].icd10 === icd10)
        return assessmentDiagnosisList[i].description;

    return "";
  };

  useEffect(() => {
    if (data.inventoryLogs) {
      let titles = data.inventoryLogs.map(item =>
        getProductTitle(item.productCode)
      );
      Promise.all(titles).then(response => setMedicines(response));
    }
  }, []);

  return (
    <div>
      <div className="mb-2">
        Clinic Staff on Duty: <strong>{data.clinicStaff}</strong>
      </div>
      <Panel heading="Medical Assessment" icon={faUserMd}>
        <MedicalAssessmentTable
          values={data}
          assessment={getDescription(data.assessment)}
          diagnosis={getDescription(data.diagnosis)}
        />
      </Panel>
      {medicines.length > 0 && (
        <Panel heading="Medicines" icon={faPills}>
          <MedicineTable values={data} productTitles={medicines} />
        </Panel>
      )}
      <Panel heading="Recommendation" icon={faNotesMedical}>
        <RecommendationTable values={data} />
      </Panel>
      <Panel heading="Physical Assessment" icon={faRunning}>
        <PhysicalAssessmentTable values={data} />
      </Panel>
    </div>
  );
};

export default PastConsultationModal;
