import React from "react";
import Select from "react-select";
import classNames from "classnames";
import styles from "./forms.module.scss";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();
// see full documentation here: https://github.com/JedWatson/react-select

const TagsInput = ({
  name,
  placeholder,
  hasLabel,
  label,
  options,
  onChange,
  value,
  onInputChange,
  inputValue,
  onKeyDown,
  defaultValue,
}) => {
  return (
    <>
      <label>{hasLabel ? label : ""}</label>
      <Select
        defaultValue={defaultValue}
        name={name}
        placeholder={placeholder}
        className={classNames(styles.SelectControl)}
        isMulti={true}
        value={value}
        options={options}
        noOptionsMessage={() => null}
        onInputChange={onInputChange}
        onChange={onChange}
        inputValue={inputValue}
        onKeyDown={onKeyDown}
        components={animatedComponents}
        theme={theme => ({
          ...theme,
        })}
      />
    </>
  );
};

export default TagsInput;
