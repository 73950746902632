import React, { useState, useEffect, Fragment, useContext } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form } from "formik";

import SearchBar from "../../Templates/Forms/SearchBar";
import Input from "../../Templates/Forms/Input";
import Panel from "../../Templates/Forms/Panel";
import PastConsultationModal from "./PastConsultationModal";
import PastVisitsSection from "./PastVisits";

import api from "../../../services/api";
import AppContext from "../../../context/AppContext";
import { EmployeeValidationSchema } from "../../Templates/Forms/ValidationSchema";

const pastVisitsHeaders = ["Date", "Chief Complaint", "Recommendation"];

export const AddEmployeeForm = ({
  setFieldValue,
  isActive,
  setActiveButton,
  isLoading,
}) => {
  return (
    <Fragment>
      <Panel heading="Patient Information">
        <Input label="First Name" type="text" name="firstName" isRequired />
        {/* <Input label="Middle Name" type="text" name="middleName" /> */}
        <Input label="Last Name" type="text" name="lastName" isRequired />
        <div className="field has-addons pt-1 pb-1">
          <label className="label pr-1">
            Sex<span className="has-text-danger"> *</span>
          </label>
          <div className="buttons has-addons">
            <button
              name="sex"
              className={classNames(
                { "is-primary": isActive === "F" },
                "button"
              )}
              type="button"
              onClick={() => {
                setActiveButton("F");
                setFieldValue("sex", "F");
              }}
            >
              Female
            </button>
            <button
              name="sex"
              className={classNames(
                { "is-primary": isActive === "M" },
                "button"
              )}
              type="button"
              onClick={() => {
                setActiveButton("M");
                setFieldValue("sex", "M");
              }}
            >
              Male
            </button>
          </div>
        </div>
        <Input label="Email Address" type="email" name="email" isRequired />
        <Input
          label="Mobile Number"
          type="text"
          name="contactNumber"
          isRequired
        />
      </Panel>

      <Panel heading="Employment Details">
        <Input
          label="Employee Number"
          type="text"
          name="employee.employeeNumber"
          placeholder="Type 'N/A' if no employee number"
          isRequired
        />
        <Input
          isRequired
          name="employee.department"
          label="Account"
          placeholder="Type 'Other' if no account/department"
          render={({ field, form, props }) => {
            return (
              <SearchBar
                id="searchAccount"
                endpoint="consultations/accounts"
                resultDisplay={["account"]}
                additionalParams={`clinic=${sessionStorage.getItem(
                  "clinicId"
                )}`}
                field={field}
                form={form}
                props={props}
                isAutocomplete={true}
                callbackFunction={result => {
                  setFieldValue("employee.department", result);

                  return result;
                }}
              />
            );
          }}
        />
        <Input
          label="Position"
          type="text"
          name="employee.position"
          placeholder="Type 'N/A' if no position"
          isRequired
        />
        <Input label="Date Hired" type="date" name="employee.dateHired" />

        <div className="buttons mt-2 is-right">
          <button
            className={classNames("button is-primary", {
              "is-loading": isLoading,
            })}
            type="submit"
            name="saveEmployeeInfo"
          >
            Save employee details
          </button>
        </div>
      </Panel>
    </Fragment>
  );
};

const DEFAULT_EMPLOYEE = {
  email: "",
  firstName: "",
  lastName: "",
  middleName: "",
  sex: "",
  contactNumber: "",
  idList: [],
  employee: {
    position: "",
    department: "",
    dateHired: "",
    employeeNumber: "",
  },
};

export const PureEmployeeDetails = ({
  values,
  setFieldValue,
  employeeStatus,
  assessmentDiagnosisList,
}) => {
  const appContext = useContext(AppContext);
  let siteDetails = appContext.siteDetails;
  const [isLoading, setLoading] = useState(false);
  const [viewType, setViewType] = useState(employeeStatus || "hasEmployee");
  const [previousViewType, setPreviousViewType] = useState("hasEmployee");
  const [isActive, setActiveButton] = useState("F");
  const [pastConsultations, setPastConsultations] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState(
    values.employee.firstName ? values.employee : DEFAULT_EMPLOYEE
  );
  const [activeConsultation, setActiveConsultation] = useState({});

  useEffect(() => {
    setViewType("hasEmployee");
    setPreviousViewType(viewType);
    if (values.employee.firstName) setEmployeeDetails(values.employee);
    else setEmployeeDetails(DEFAULT_EMPLOYEE);
  }, [values.employee]);

  useEffect(() => {
    if (activeConsultation.id) {
      api.get(`consultations/${activeConsultation.id}/`).then(response => {
        appContext.useModal(
          <PastConsultationModal
            data={{
              ...response.data,
              clinicStaff: activeConsultation.clinicStaff,
            }}
            assessmentDiagnosisList={assessmentDiagnosisList}
          />,
          "primary",
          "View Consultation"
        );
        setActiveConsultation({});
      });
    }
  }, [activeConsultation]);

  useEffect(() => {
    if (!!employeeDetails && !!employeeDetails.firstName) {
      let values = [
        employeeDetails.firstName,
        employeeDetails.lastName,
        employeeDetails.employee.employeeNumber,
      ];
      let searchParams = [];
      values.map(value => {
        if (!!value) searchParams = [...searchParams, value];
      });

      // We're using this endpoint to search across a client's consultations with the employee's first & last name + employee number
      // to accommodate cases like Baguio sites. We want to pull up site visits of an employee regardless which clinic they consulted in,
      // e.g. in Baguio 1 or 2; hence filtering by client instead of clinic id

      api
        .get(
          `/consultations/report/?client=${sessionStorage.getItem(
            "clientCode"
          )}&search=${searchParams.join(" ")}`
        )
        .then(response => {
          setPastConsultations(response.data.results);
        });
    }
  }, [employeeDetails.firstName, employeeDetails.lastName]);

  useEffect(() => {
    siteDetails = appContext.siteDetails;
  }, [appContext.siteDetails]);

  const reconstructValues = values => {
    console.log({
      id: values.id || null,
      email: values.email || "",
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName || "",
      contactNumber: values.contactNumber || "",
      sex: values.sex,
      idList: [],
      employee: {
        site: {
          id: siteDetails.id,
          name: siteDetails.name,
          siteLetters: siteDetails.siteLetters,
          siteNumber: siteDetails.siteNumber,
          corporateClientId: siteDetails.corporateClient.id,
        },
        position: values.employee.position,
        department: values.employee.department,
        employeeNumber: values.employee.employeeNumber,
        dateHired: values.employee.dateHired || null,
      },
    });
    return {
      id: values.id || null,
      email: values.email || "",
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName || "",
      contactNumber: values.contactNumber || "",
      sex: values.sex,
      idList: [],
      employee: {
        site: {
          id: siteDetails.id,
          name: siteDetails.name,
          siteLetters: siteDetails.siteLetters,
          siteNumber: siteDetails.siteNumber,
          corporateClientId: siteDetails.corporateClient.id,
        },
        position: values.employee.position,
        department: values.employee.department,
        employeeNumber: values.employee.employeeNumber,
        dateHired: values.employee.dateHired || null,
      },
    };
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") event.preventDefault();
  };

  const handlePastVisitsCallback = item => {
    setActiveConsultation(item);
  };

  return (
    <Fragment>
      {viewType === "hasEmployee" && (
        <>
          {employeeDetails.firstName ? (
            <>
              <Panel heading="Patient Information">
                <article className="media">
                  <div className="media-content">
                    <div className="content">
                      <h1 className="title is-size-4 mb-2">{`${
                        employeeDetails.firstName
                      } ${employeeDetails.lastName}`}</h1>

                      <span className="has-text-weight-bold">
                        Employee Number:{" "}
                      </span>
                      <span>{employeeDetails.employee.employeeNumber}</span>
                      <br />

                      <span className="has-text-weight-bold">Department: </span>
                      <span>{employeeDetails.employee.department}</span>
                      <br />

                      <span className="has-text-weight-bold">
                        Contact Number:{" "}
                      </span>
                      <span>{employeeDetails.contactNumber}</span>

                      <div className="is-size-7 mt-2">
                        Not this employee?{" "}
                        <span
                          className="link"
                          name="searchEmployeeButton"
                          onClick={() => {
                            setFieldValue("employee", DEFAULT_EMPLOYEE);
                            setFieldValue("employeeName", "");
                          }}
                        >
                          <strong>Change employee</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="media-right">
                    <button
                      className="button is-primary"
                      title="Edit employee details"
                      type="button"
                      name="editEmployeeButton"
                      onClick={() => {
                        setViewType("isEditing");
                        setPreviousViewType("hasEmployee");
                      }}
                    >
                      <span className="icon is-left">
                        <FontAwesomeIcon icon={faEdit} />
                      </span>
                    </button>
                  </div>
                </article>
              </Panel>

              <Panel heading="Past Visits">
                <div className="is-size-6 mb-1">
                  <span>Select a row to view its full details.</span>
                </div>
                <PastVisitsSection
                  headers={pastVisitsHeaders}
                  pastVisits={pastConsultations}
                  callback={handlePastVisitsCallback}
                />
              </Panel>
            </>
          ) : (
            <p
              className="has-text-centered is-size-6 mt-5"
              id="employeeWarning"
            >
              <strong>Please select an employee.</strong>
            </p>
          )}

          {/* <Panel heading="Patient History">
            <PatientHistorySection values={values} />
          </Panel> */}
        </>
      )}

      {viewType === "isEditing" && (
        <>
          <button
            type="button"
            className="button is-primary"
            name="returnButton"
            onClick={() => {
              setViewType(previousViewType);
              if (previousViewType === "isSearching")
                setEmployeeDetails(DEFAULT_EMPLOYEE);
              setPreviousViewType("isEditing");
              return previousViewType;
            }}
          >
            {"<"} Go back
          </button>
          <br />
          <br />
          <Formik
            initialValues={employeeDetails}
            onSubmit={values => {
              api
                .put("consultations/employees/", reconstructValues(values), {
                  headers: {
                    Authorization: `${sessionStorage.getItem("loginToken")}`,
                  },
                })
                .then(response => {
                  setFieldValue("employee", response.data);
                  setFieldValue(
                    "employeeName",
                    `${response.data.firstName} ${response.data.lastName}`
                  );
                  setEmployeeDetails(reconstructValues(values));
                  setViewType("hasEmployee");
                  setPreviousViewType("isEditing");
                  setLoading(false);
                });
            }}
            validationSchema={EmployeeValidationSchema}
          >
            {({ setFieldValue, errors }) => {
              return (
                <Form autoComplete="off" onKeyDown={handleKeyDown}>
                  <AddEmployeeForm
                    errors={errors}
                    setFieldValue={setFieldValue}
                    isActive={isActive}
                    setActiveButton={setActiveButton}
                    isLoading={isLoading}
                    values={values}
                  />
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Fragment>
  );
};

const EmployeeDetails = props => {
  return <PureEmployeeDetails {...props} />;
};

export default EmployeeDetails;
