import React, { useState } from "react";
import { Field } from "formik";
import classNames from "classnames";

import Input from "../../Templates/Forms/Input";
import AddDoctor from "./AddDoctor";
import SearchBar from "../../Templates/Forms/SearchBar";
import Datalist from "../../Templates/Forms/Datalist";

// import ConsultLoggedByField from "./ConsultLoggedByField";

import { removeButton } from "./consultationForm.module.scss";

/** MEDICAL ASSESSMENT */
/* eslint-disable */
const MedicalAssessment = props => {
  const { errors } = props;
  const getDescription = id => {
    let assessmentList = props.assessmentDiagnosisList;
    for (let i = 0; i < assessmentList.length; i++)
      if (assessmentList[i].id === id) return assessmentList[i].description;

    return "";
  };

  const [selected, setSelected] = useState(null);
  const [isDisabled, setDisabledObjects] = useState({
    assessmentNotes: !props.values.nurseAssessment,
    diagnosisNotes: !props.values.doctorsDiagnosis,
  });
  const [isHidden, setHiddenObjects] = useState({
    doctorDetails: props.values.doctor.firstName ? false : true,
    doctorSearch: props.hideDoctorSearch ? false : true,
  });
  const [assessmentValue, setAssessmentValue] = useState(
    props.values.nurseAssessment
      ? {
          label: getDescription(props.values.nurseAssessment),
          value: getDescription(props.values.nurseAssessment),
        }
      : null
  );
  const [diagnosisValue, setDiagnosisValue] = useState(
    props.values.doctorsDiagnosis
      ? {
          label: getDescription(props.values.doctorsDiagnosis),
          value: getDescription(props.values.doctorsDiagnosis),
        }
      : null
  );

  const assessmentList = props.assessmentDiagnosisList.map(
    assessmentDiagnosis => ({
      value: assessmentDiagnosis.id,
      label: assessmentDiagnosis.label,
    })
  );

  const removeDiagnosis = (setFieldValue, type) => {
    switch (type) {
      case "assessment":
        setAssessmentValue("");
        break;
      case "diagnosis":
        setHiddenObjects({
          doctorDetails: true,
          doctorSearch: true,
        });
        setDiagnosisValue("");
        break;
    }
    setDisabledObjects({
      ...isDisabled,
      [`${type}Notes`]: true,
    });
    setFieldValue(type, "");
    setFieldValue(`${type}Notes`, "");
  };

  return (
    <>
      <div className="columns mb-0">
        <div className="column pb-0 is-4">
          <Field name="nurseAssessment">
            {({ field, form }) => (
              <Datalist
                name="assessment"
                defaultValue={assessmentValue}
                value={assessmentValue}
                label="Nurse's Assessment"
                hasLabel={true}
                placeholder="Select from list below..."
                options={assessmentList}
                field={field}
                isRequired
                onChange={selected => {
                  setSelected(selected);
                  if (selected !== "") {
                    setDisabledObjects({
                      ...isDisabled,
                      assessmentNotes: false,
                    });
                  } else {
                    setDisabledObjects({
                      ...isDisabled,
                      assessmentNotes: true,
                    });
                  }
                  setAssessmentValue(selected);
                  form.setFieldValue("nurseAssessment", selected.value);
                }}
              />
            )}
          </Field>
          {errors.nurseAssessment ? (
            <div className="mt-2">
              <div className="has-text-danger is-size-7">
                {errors.nurseAssessment}
              </div>
            </div>
          ) : null}
        </div>
        <div className="column pb-0 is-expanded">
          <Input
            name="assessmentNotes"
            type="text"
            label="Additional Notes"
            placeholder="Additional Notes"
            isDisabled={isDisabled.assessmentNotes}
          />
        </div>
        {!isDisabled.assessmentNotes && (
          <div className="column is-1">
            <button
              type="button"
              className={classNames("delete is-medium mt-5", removeButton)}
              onClick={() => removeDiagnosis(props.setFieldValue, "assessment")}
            />
          </div>
        )}
      </div>
      <div className="columns">
        <div className="column is-4">
          <Field name="diagnosis">
            {({ field, form }) => (
              <Datalist
                name="diagnosis"
                defaultValue={diagnosisValue}
                label="Doctor's Diagnosis"
                hasLabel={true}
                placeholder="Select from list below..."
                options={assessmentList}
                field={field}
                value={diagnosisValue}
                onChange={selected => {
                  setSelected(selected);
                  if (selected !== "") {
                    setDisabledObjects({
                      ...isDisabled,
                      diagnosisNotes: false,
                    });
                    setHiddenObjects({ ...isHidden, doctorSearch: false });
                  } else {
                    setDisabledObjects({
                      ...isDisabled,
                      diagnosisNotes: true,
                    });
                    setHiddenObjects({
                      doctorDetails: true,
                      doctorSearch: true,
                    });
                    form.setFieldValue("doctor", null);
                  }
                  setDiagnosisValue(selected);
                  form.setFieldValue("doctorsDiagnosis", selected.value);
                }}
              />
            )}
          </Field>
          {!isHidden.doctorSearch ? (
            <div className="mt-2">
              <SearchBar
                id="doctorSearch"
                type="text"
                endpoint="staff"
                name="doctor"
                itemDescription="doctors"
                label="Add Doctor"
                placeholder="Search for doctor..."
                resultDisplay={["firstName", "lastName"]}
                additionalParams={"staff_type=MD"}
                callbackFunction={result => {
                  props.values.doctor = result;
                  setHiddenObjects({
                    ...isHidden,
                    doctorSearch: true,
                    doctorDetails: false,
                  });
                  props.setFieldValue("doctor", result);
                  return result;
                }}
              />
              <p className="is-size-7">
                Can't find doctor?{" "}
                <span
                  className="link"
                  name="changeDoctorLink"
                  id="addDoctorLink"
                  onClick={() => {
                    props.appContext.useModal(
                      <AddDoctor
                        doctor={props.values}
                        closeModal={props.appContext.closeModal}
                        setHiddenObjects={setHiddenObjects}
                        isHidden={isHidden}
                        setFieldValue={props.setFieldValue}
                      />,
                      "primary",
                      "ADD NEW DOCTOR"
                    );
                  }}
                >
                  <strong>Add new doctor</strong>
                </span>
              </p>
              {props.values.diagnosis ? (
                <div className="has-text-danger is-size-7">
                  Doctor's information is required when adding a diagnosis.
                </div>
              ) : null}
            </div>
          ) : !isHidden.doctorDetails ? (
            <div>
              <p className="is-size-6 mt-2">
                <strong>
                  {`${props.values.doctor.firstName} ${
                    props.values.doctor.lastName
                  } (${props.values.doctor.staffType})`}
                </strong>
              </p>{" "}
              <p className="is-size-7">
                Not this doctor?{" "}
                <span
                  className="link"
                  name="changeDoctorLink"
                  id="changeDoctorLink"
                  onClick={() => {
                    setHiddenObjects({
                      ...isHidden,
                      doctorSearch: false,
                      doctorDetails: true,
                    });
                    props.setFieldValue("doctor", { firstName: "" });
                    return null;
                  }}
                >
                  <strong>Change doctor</strong>
                </span>
              </p>
            </div>
          ) : null}
        </div>
        <div className="column is-expanded">
          <Input
            name="diagnosisNotes"
            type="text"
            label="Additional Notes"
            placeholder="Additional Notes"
            isDisabled={isDisabled.diagnosisNotes}
          />
        </div>
        {!isDisabled.diagnosisNotes && (
          <div className="column is-1">
            <button
              type="button"
              className={classNames("delete is-medium mt-5", removeButton)}
              onClick={() => removeDiagnosis(props.setFieldValue, "diagnosis")}
            />
          </div>
        )}
      </div>
      <Input
        label="Remarkable Findings"
        type="string"
        name="remarkableFindings"
      />{" "}
      {/* <ConsultLoggedByField
        type={props.formType}
        values={props.values}
        setFieldValue={props.setFieldValue}
      /> */}
      <Input
        label="RN on duty (MedGrocer email)"
        placeholder="jdelacruz@medgrocer.clinic"
        type="string"
        name="medstaffEmail"
        isDisabled={props.formType === "edit"}
        isRequired
      />{" "}
    </>
  );
};

export default MedicalAssessment;
