import React from "react";
import { FieldArray } from "formik";
import classNames from "classnames";
import moment from "moment";

import styles from "./consultationForm.module.scss";

import Input from "../../Templates/Forms/Input";
import SearchBar from "../../Templates/Forms/SearchBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const MedicineFields = ({ index, arrayHelpers, values, appContext }) => {
  return (
    <>
      <div className="columns mb-0" name="medicines-row">
        <div className="column is-8">
          {appContext.siteDetails.corporateClient.priceListCode && (
            <SearchBar
              id={`searchMeds${index}`}
              endpoint="inventory/fetch_products"
              name={`logs[${index}].productName`}
              placeholder="Search medicines here..."
              resultDisplay={["title"]}
              additionalParams={`price_list=clinics_default`}
              callbackFunction={result => {
                values.product = result.itemCode;
                values.product_administration = result.administration || "";
                // logged and clinics are not final yet
                values.logged = moment();
                values.clinic = null;
                values.reason = "dispensed";
                values.product_title = result.title;
                values.quantity = 1;

                return result;
              }}
            />
          )}
        </div>
        <div className="column is-2">
          <Input
            name={`logs[${index}].quantity`}
            min={0}
            defaultValue={1}
            type="number"
            placeholder="1"
          />
        </div>
        <div className="column is-1">
          <button
            className={classNames(
              "delete is-medium mt-1",
              styles.removeMedicine
            )}
            onClick={() => arrayHelpers.remove(index)}
            type="button"
          >
            <FontAwesomeIcon icon={faTimes} className="has-text-danger" />
          </button>
        </div>
      </div>
    </>
  );
};
const MedicineSection = props => {
  const headers = [
    {
      header: "Medicines",
      width: 8,
    },
    {
      header: "Qty",
      width: 2,
    },
  ];

  return (
    <>
      <FieldArray
        name="logs"
        render={arrayHelpers => (
          <>
            {props.values.logs.length > 0 && (
              <div className="columns">
                {headers.map((header, index) => (
                  <div className={`column is-${header.width} pb-0`} key={index}>
                    <label className="label has-text-weight-normal has-text-grey-dark">
                      {header.header}
                    </label>
                  </div>
                ))}
              </div>
            )}
            {props.values.logs.map((medicine, index) => (
              <MedicineFields
                key={index}
                index={index}
                values={medicine}
                onChange={props.handleChange}
                arrayHelpers={arrayHelpers}
                appContext={props.appContext}
              />
            ))}
            <button
              name="add-row"
              type="button"
              className="button"
              onClick={() => {
                arrayHelpers.push({});
              }}
            >
              + Add medicine
            </button>
          </>
        )}
      />
    </>
  );
};

export default MedicineSection;
