import React from "react";
import { Field } from "formik";

import CustomField from "../../Templates/CustomField/CustomField";
import TextArea from "../../Templates/Forms/Textarea";

const CustomFields = ({ customFields, emptyFields, values }) => (
  <div className="mt-1">
    {customFields.map(item => (
      <CustomField
        customField={item}
        emptyFields={emptyFields}
        values={values}
      />
    ))}
    <div className="columns">
      <div className="column py-0">
        <Field name="notes">
          {({ field }) => (
            <TextArea
              field={field}
              label="Other notes"
              placeholder="Advised to increase fluid intake"
              rows={3}
            />
          )}
        </Field>
      </div>
    </div>
  </div>
);

export default CustomFields;
