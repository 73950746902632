import React, { Fragment } from "react";
import classNames from "classnames";
import Input from "../../Templates/Forms/Input";

const ConsultType = ({ values, handleClick, errors }) => {
  return (
    <Fragment>
      <div className="buttons has-addons">
        <button
          className={classNames("button is-medium px-3", {
            "is-primary": values.consultType === "Physical",
          })}
          name="consultType"
          id="Physical"
          onClick={handleClick}
          type="button"
        >
          Physical
        </button>
        <button
          className={classNames("button is-medium px-3", {
            "is-primary": values.consultType === "Teleconsult",
          })}
          name="consultType"
          id="Teleconsult"
          onClick={handleClick}
          type="button"
        >
          Teleconsult
        </button>
      </div>

      {values.consultType === "Teleconsult" ? (
        <div className="columns is-mobile">
          <div className="column is-narrow">
            <p className="has-text-weight-bold my-1">Status</p>
            <div className="buttons has-addons">
              <button
                className={classNames("button", {
                  "is-primary": values.status === "Scheduled",
                })}
                name="status"
                id="Scheduled"
                onClick={handleClick}
                type="button"
              >
                Scheduled
              </button>
              <button
                className={classNames("button", {
                  "is-primary": values.status === "Completed",
                })}
                name="status"
                id="Completed"
                onClick={handleClick}
                type="button"
              >
                Completed
              </button>
            </div>
            <div className="has-text-danger is-size-7">{errors.status}</div>
          </div>
          <div className="column">
            <Input
              type="datetime-local"
              name="scheduled"
              label="Schedule"
              isDisabled={values.status === "Completed"}
            />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ConsultType;
