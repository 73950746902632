import React, { useState, useEffect, Fragment } from "react";
// import classNames from "classnames";
import { Field } from "formik";
import TextArea from "../../Templates/Forms/Textarea";
// import Select from "../../Templates/Forms/Select";

import TagsInput from "../../Templates/Forms/TagsInput";
import api from "../../../services/api";
import Datalist from "../../Templates/Forms/Datalist";

const Recommendation = props => {
  const { errors, touched, setFieldValue, values } = props;
  const [showBreakDuration, setDisableBreakDuration] = useState(true);
  const [isActive, setActivebutton] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [recommendationsLoading, setRecommendationsLoading] = useState(true);
  const [initialRecommendation, setInitialRecommendation] = useState(
    values.recommendation
      ? {
          value: values.recommendation,
          label: values.recommendation,
        }
      : null
  );
  // tags for clinic passes
  /* eslint-disable */
  const [tags, setTags] = useState(values.clinicPasses || []);

  useEffect(() => {
    if (!recommendations.length)
      api
        .get(
          `recommendations/choices/?clinic=${sessionStorage.getItem(
            "clinicId"
          )}`
        )
        .then(response => {
          setRecommendationsLoading(false);
          setRecommendations(
            response.data.map(recommendation => ({
              label: recommendation.recommendation,
              value: recommendation.recommendation,
            }))
          );
        });
  }, [recommendations]);

  const options = [
    {
      value: "Face mask",
      label: "Face mask",
    },
    {
      value: "Food",
      label: "Food",
    },
    {
      value: "Tissue",
      label: "Tissue",
    },
    {
      value: "Slippers",
      label: "Slippers",
    },
  ];

  const setValueRecommendation = (values, value) => event => {
    if (values) {
      const validator = value === "Health Break";
      setFieldValue("recommendation", validator ? "" : value);
      setDisableBreakDuration(!validator);
    }
    setActivebutton(value);
    return values;
  };

  return (
    <>
      <Field name="recommendation">
        {({ field, form }) => (
          <Datalist
            isRequired
            name="recommendation"
            label="Recommendation"
            hasLabel={true}
            placeholder="Select from list below..."
            options={recommendations}
            defaultValue={initialRecommendation}
            field={field}
            isLoading={recommendationsLoading}
            onChange={selected => {
              form.setFieldValue("recommendation", selected.value);
            }}
          />
        )}
      </Field>
      <div className="mt-2">
        {errors.recommendation || touched.recommendation ? (
          <div className="has-text-danger is-size-7">
            {errors.recommendation}
          </div>
        ) : null}
      </div>
      <br />
      <div className="columns">
        <div className="column mb-2">
          <Field name="clinicPasses">
            {({ field, form }) => (
              <>
                <label htmlFor="clinicPasses">Clinic Passes</label>
                <label className="help">
                  Press tab or enter to add more than one pass
                </label>
                <TagsInput
                  defaultValue={tags.map(item => ({
                    value: item,
                    label: item,
                  }))}
                  options={options}
                  name="clinicPasses"
                  placeholder="Add pass"
                  onChange={(value, { action }) => {
                    if (value) {
                      setTags(value);
                      form.setFieldValue(
                        "clinicPasses",
                        value.map(item => item.label)
                      );
                    } else {
                      setTags([]);
                      form.setFieldValue("clinicPasses", []);
                    }
                  }}
                />
              </>
            )}
          </Field>
          {!props.hasCustomFields && (
            <Fragment>
              <br />
              <Field name="notes">
                {({ field, form }) => (
                  <TextArea
                    field={field}
                    label="Other notes"
                    placeholder="Advised to increase fluid intake"
                    rows={3}
                  />
                )}
              </Field>
            </Fragment>
          )}
        </div>
      </div>
    </>
  );
};

export default Recommendation;
