import React, { useState } from "react";

import { Formik, Form } from "formik";
import Input from "../../Templates/Forms/Input";
import { DoctorValidationSchema } from "../../Templates/Forms/ValidationSchema";
import api from "../../../services/api";

/** Add Doctor Modal */
const AddDoctor = ({
  doctor,
  closeModal,
  setHiddenObjects,
  isHidden,
  initialValues,
  setFieldValue,
}) => {
  const reconstructValues = values => ({
    ...values,
    clinic: [
      {
        name: sessionStorage.getItem("clinicName"),
        site: sessionStorage.getItem("siteName"),
        client: { id: parseInt(sessionStorage.getItem("clientId")) },
      },
    ],
    // comment the line below to test error message,
    // remove these comments if necessary before merging :)
    // start comment below this line
    mainClinic: {
      name: sessionStorage.getItem("clinicName"),
      site: sessionStorage.getItem("siteName"),
      client: { id: parseInt(sessionStorage.getItem("clientId")) },
    },
    // end comment below this line
  });

  const [error, setError] = useState({
    active: false,
    message: "",
  });

  const handleSubmit = async values => {
    await api
      .post("/staff/", reconstructValues(values))
      .then(response => {
        setFieldValue("doctor", response.data);
        closeModal();
        setHiddenObjects({
          ...isHidden,
          doctorSearch: true,
          doctorDetails: false,
        });
      })
      .catch(err =>
        setError({ active: true, message: JSON.stringify(err.response.data) })
      );

    // return doctor.doctor
    // return 'IT WORKS'
  };

  return (
    <div>
      {error.active && (
        <div className="notification is-warning has-text-centered">
          Error: {error.message}. Please contact admin for info.
        </div>
      )}
      <Formik
        initialValues={
          initialValues || {
            firstName: "",
            lastName: "",
            staffType: "MD",
            licenseNumber: "",
            contactNumber: "",
            clinic: [],
          }
        }
        id="addDoctorForm"
        onSubmit={handleSubmit}
        validationSchema={DoctorValidationSchema}
      >
        {formikHelpers => {
          return (
            <Form autoComplete="off">
              <div className="columns is-centered">
                <div className="column is-8">
                  <Input
                    label="First Name"
                    name="firstName"
                    placeholder="Juan"
                    isRequired
                  />
                  <Input
                    label="Last Name"
                    name="lastName"
                    placeholder="Dela Cruz"
                    isRequired
                  />
                  <Input
                    label="License Number"
                    name="licenseNumber"
                    placeholder="82459612"
                    isRequired
                    maxLength={7}
                  />
                  <Input
                    label="Contact Number"
                    name="contactNumber"
                    placeholder="09123456789"
                    maxLength={11}
                  />
                </div>
              </div>
              <div className="buttons is-right">
                <button
                  type="submit"
                  className="button is-primary"
                  disabled={Object.keys(formikHelpers.errors).length > 0}
                >
                  Add new doctor
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddDoctor;
