import React from "react";
import moment from "moment";

const VisitRow = ({ data, callback }) => (
  <tr onClick={() => callback(data)}>
    <td>{moment(data.created).format("MMM DD")}</td>
    <td>{data.chiefComplaint}</td>
    <td>{data.recommendation}</td>
  </tr>
);

const PastVisitsSection = props => {
  return (
    <>
      <table className="table is-size-7 is-fullwidth is-striped is-hoverable is-selectable">
        <thead>
          <tr className="has-background-primary">
            {props.headers.map(item => (
              <th className="has-text-white">{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.pastVisits.map(item => (
            <VisitRow data={item} callback={props.callback} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PastVisitsSection;
