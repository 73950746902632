import React, { Fragment } from "react";
import classNames from "classnames";
import Input from "../Forms/Input";
import Datalist from "../Forms/Datalist";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import TextArea from "../Forms/Textarea";

const generateDatalistValue = value => {
  if (value)
    return {
      label: value,
      value: value,
    };
  return "";
};

const CustomField = ({
  customField,
  customClassName,
  emptyFields,
  values,
  hideAdditionalNotes,
  rows,
  errors,
}) => {
  const handleBooleanChange = (form, name, value) => {
    form.setFieldValue(name, value);
  };

  switch (customField.dataType) {
    case "round":
    case "decimal":
    case "text":
      return (
        <Fragment>
          <Input
            description={customField.description}
            name={customField.name}
            label={customField.label}
            isRequired={customField.isRequired}
            className={customClassName}
            type={customField.dataType === "text" ? "text" : "number"}
          />
          {!!errors && !!errors[customField.name] && (
            <div className="is-size-7 has-text-danger mb-1">
              {errors[customField.name]}
            </div>
          )}
        </Fragment>
      );
    case "pick_list_one":
      return (
        <div className="columns">
          <div
            className={classNames("column py-0", {
              "is-4": !hideAdditionalNotes,
            })}
          >
            <Field name={customField.name}>
              {({ field, form }) => (
                <Datalist
                  description={customField.description}
                  field={field}
                  name={customField.name}
                  label={customField.label}
                  defaultValue={generateDatalistValue(values[customField.name])}
                  hasLabel
                  placeholder="Select from list below..."
                  options={customField.values.map(item => ({
                    value: item,
                    label: item,
                  }))}
                  isRequired={customField.isRequired}
                  onChange={selected => {
                    form.setFieldValue(customField.name, selected.value);
                  }}
                />
              )}
            </Field>
            {!!errors && !!errors[customField.name] && (
              <div className="is-size-7 has-text-danger mb-1">
                {errors[customField.name]}
              </div>
            )}
          </div>
          {!hideAdditionalNotes && (
            <div className="column py-0">
              <Input
                name={`${customField.name}Notes`}
                label="Additional Notes"
                className={customClassName}
                isDisabled={values[customField.name] !== "Other"}
              />
            </div>
          )}
        </div>
      );
    case "boolean":
      return (
        <div className="control">
          <Field name={customField.name}>
            {({ form }) => (
              <Fragment>
                <label>
                  {customField.label}
                  {!!customField.required && (
                    <span className="has-text-danger has-text-weight-bold">
                      &nbsp;*
                    </span>
                  )}
                  {customField.description && (
                    <span
                      className="icon has-text-grey-light mr-1 tooltip tooltip-text is-tooltip-top"
                      data-tooltip={customField.description}
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} size="1x" />
                    </span>
                  )}
                </label>
                <span className="mr-1">
                  <input
                    className="radio"
                    id="yesRadioButton"
                    type="radio"
                    name={customField.name}
                    value={true}
                    onChange={event =>
                      handleBooleanChange(
                        form,
                        customField.name,
                        event.target.value
                      )
                    }
                  />
                  <label for="yesRadioButton">Yes</label>
                </span>
                <input
                  className="radio"
                  id="noRadioButton"
                  type="radio"
                  value={false}
                  name={customField.name}
                  onChange={event =>
                    handleBooleanChange(
                      form,
                      customField.name,
                      event.target.value
                    )
                  }
                />
                <label for="noRadioButton">No</label>
              </Fragment>
            )}
          </Field>
          {emptyFields.includes(customField.name) && customField.isRequired && (
            <div className="is-size-7 has-text-danger">
              This field is required.
            </div>
          )}
        </div>
      );
    case "textarea":
      return (
        <Fragment>
          <Field name={customField.name}>
            {({ field }) => (
              <TextArea
                value={values[customField.name]}
                field={field}
                name={customField.name}
                description={customField.description}
                label={customField.label}
                isRequired={customField.isRequired}
                className={customClassName}
                type={customField.dataType === "text" ? "text" : "number"}
                rows={rows || 5}
              />
            )}
          </Field>
          {emptyFields.includes(customField.name) && customField.isRequired && (
            <span className="is-size-7 has-text-danger">
              This field is required.
            </span>
          )}
        </Fragment>
      );
    default:
      return null;
  }
};

export default CustomField;
